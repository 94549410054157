import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import sha256 from "sha256";
import {
  Button,
  Row,
  FormGroup,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../api";
import { Alert } from "reactstrap";
import FontAwesome from "react-fontawesome";
import Info from "@material-ui/icons/Info";
import { useSelector, useDispatch } from "react-redux";
import OBlogo from "../assets/logo/branding-2@2x.png";
import "./signin.css";
import { IconButton } from "@material-ui/core";
import Logout from "../logOut/Logout";
import Loader from "react-js-loader";

export default function SingUp() {
  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [message, setMessage] = useState("");
  const [alertClr, setAlertClr] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [isInfoClick, setIsInfoClick] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const profile = useSelector((state) => state.profileData);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({ type: "setCurrentLink", currentLink: "/signUp" });
  }, []);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setMessage("");
        setAlertClr("");
        setShowAlert(false);
      }, 3000);
    }
  }, [showAlert]);

  const validatePassword = () => {
    var isValidPass = true;

    if (password.trim().length < 6 || password.trim().length > 20) {
      // setPasswordErr(true);
      setPasswordErr("Password should be minimum 6 and maximum 20 characters");
      isValidPass = false;
    } else {
      setPasswordErr("");
      isValidPass = true;
    }
    return isValidPass;
  };

  const validateMobileNumber = () => {
    let isNumValid = true;
    // reg expression for phone number
    var regE1 = new RegExp(/[^0-9]/gi, "");
    var regE2 = new RegExp(/^[0,1,8][0-9]{0,8}$/);

    // to set primary contact phone
    if (!regE1.test(mobile)) {
      setMobileErr("");
      isNumValid = true;
      if (!regE2.test(mobile) && mobile.toString().length === 8) {
        setMobileErr("");
        isNumValid = true;
      } else {
        isNumValid = false;
        setMobileErr("Please enter valid phone number");
      }
    } else {
      isNumValid = false;
      setMobileErr("Only Numbers are allowed");
    }
    return isNumValid;
  };

  const signUpUser = () => {
    dispatch({ type: "setOtp_timer_value", OTP_Timer_value: 300000 });

    console.log("SIGN IN CALL");
    var regEx = new RegExp(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i);
    validateMobileNumber();
    validatePassword();
    if (firstName.length === 1) {
      setFirstNameErr("Please enter valid first name");
    } else if (firstName.length === 0) {
      setFirstNameErr("Please enter your first name ");
    } else {
      setFirstNameErr("");
    }
    if (lastName.length === 1) {
      setLastNameErr("Please enter valid last name");
    } else if (lastName.length === 0) {
      setLastNameErr("Please enter your last name");
    } else {
      setLastNameErr("");
    }
    if (email.length === 0) {
      setEmailErr("Please enter your email");
    } else if (!regEx.test(email)) {
      setEmailErr("Please enter valid email");
    } else {
      setEmailErr("");
    }

    if (password.length === 0) {
      setPasswordErr("Please enter your password");
    } else if (password.trim().length < 6 || password.trim().length > 20) {
      setPasswordErr("Password should be minimum 6 and maximum 20 characters");
    } else {
      setPasswordErr("");
    }

    if (validateMobileNumber() && validatePassword()) {
      var data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobile: Number(mobile),
        passwordHash: sha256(password),
        deviceFcmToken: `WEB_TKN_${email}`,
        deviceType: 3,
      };

      let userData = [];
      userData.push(data.deviceFcmToken);
      userData.push(data.email);
      // localStorage.userEmail = data.deviceFcmToken;
      dispatch({ type: "set_profile_data", profileData: { ...profile, data } });
      setIsLoading(true);
      if (regEx.test(email)) {
        api.auth
          .signUp(data)
          .then((result) => {
            console.log("SIGN UP RESULT ===>", result);
            if (result.resultCode === 1) {
              // setMessage(
              //   `Welcome ${firstName}! Opportunity Bank team welcomes you onboard`
              // );
              // setAlertClr("success");
              // setShowAlert(true);

              setTimeout(() => {
                history.push({
                  pathname: "/OTP",
                  state: {
                    status: "fromSignup",
                    email: email,
                  },
                });
                setFirstName("");
                setLastName("");
                setEmail("");
                setPassword("");
                setMobile("");
              }, 3000);
            } else if (result.resultCode === 0) {
              console.log("result.resultMsg ", result.resultMsg);
              if (
                result.resultMsg ===
                "User with this Mobile number is already signed up."
              ) {
                setMessage("User with this mobile number already exists");
                setAlertClr("danger");
                setShowAlert(true);
              }
              if (
                result.resultMsg ===
                "User with this Email ID is already signed up."
              ) {
                setMessage("User with this email address already exists");
                setAlertClr("danger");
                setShowAlert(true);
              }
            }
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            if (
              !!error.response &&
              !!error.response.status &&
              error.response.status === 401
            ) {
              Logout(dispatch, history, false);
            }
            if (error.message === "Network Error") {
              setMessage("Please check your network connection!");
              setAlertClr("danger");
              setShowAlert(true);
            }
          });
      }
    }
    window.scrollTo(0, 0);
  };

  return (
    <>
      {isLoading && (
        <Loader
          type="spinner-cub"
          bgColor={"rgb(53, 50, 50)"}
          title={"Loading..."}
          size={100}
        />
      )}

      <Modal
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={showModal}
        show={showModal}
      >
        <Modal.Body>
          Password should be minimum 6 and maximum 20 characters
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Alert className="alert-position" isOpen={showAlert} color={alertClr}>
          {message}{" "}
        </Alert>
        <div className="container form-container mt-5">
          <img src={OBlogo} className="loginLogo mt-2" />

          <Form className="login-form">
            <Form.Group
              className="d-flex justify-content-center mt-3"
              style={{ color: "#5B5A5A" }}
            >
              <h3>SignUp</h3>
            </Form.Group>
            <Form.Group>
              <Form.Label className="shiftToLeft cust-label">
                First Name
                <span className="mandatory-star ml-2">*</span>
              </Form.Label>
              <Form.Control
                className="cust-inputStyle"
                value={firstName}
                onChange={(e) => {
                  let reg = new RegExp(/[^A-Za-z]/gi, "");
                  if (!reg.test(e.target.value)) {
                    setFirstName(e.target.value);
                  }
                  if (e.target.value) {
                    setFirstNameErr("");
                  }
                }}
                name="firstName"
                type="text"
              />
              <Form.Text
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "emoji",
                }}
              >
                {firstNameErr}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="cust-label shiftToLeft">
                Last Name
                <span className="mandatory-star ml-2">*</span>
              </Form.Label>
              <Form.Control
                className="cust-inputStyle"
                value={lastName}
                onChange={(e) => {
                  let reg = new RegExp(/[^A-Za-z]/gi, "");
                  if (!reg.test(e.target.value)) {
                    setLastName(e.target.value);
                  }
                  if (e.target.value) {
                    setLastNameErr("");
                  }
                }}
                type="text"
                name="lastName"
              />
              <Form.Text
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "emoji",
                }}
              >
                {lastNameErr}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="cust-label shiftToLeft">
                Email Address
                <span className="mandatory-star ml-2">*</span>
              </Form.Label>
              <Form.Control
                className="cust-inputStyle"
                value={email}
                onChange={(e) => {
                  if (!e.target.value.includes(" ")) {
                    setEmail(e.target.value);
                  }
                  if (e.target.value) {
                    setEmailErr("");
                  }
                }}
                name="email"
                type="email"
              />
              <Form.Text
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "emoji",
                }}
              >
                {emailErr}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="cust-label shiftToLeft">
                Mobile Number
                <span className="mandatory-star ml-2">*</span>
              </Form.Label>
              <Form.Control
                className="cust-inputStyle"
                value={mobile}
                name="mobile"
                onChange={(e) => {
                  // to set primary contact phone
                  var regE1 = new RegExp(/[^0-9]/gi, "");
                  // var regE2 = new RegExp(/^[0,1,4,8][0-9]{0,8}$/);

                  if (!regE1.test(e.target.value)) {
                    setMobile(e.target.value);
                    setMobileErr("");
                  }
                }}
                type="text"
                maxLength="8"
              />
              <Form.Text
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "emoji",
                }}
              >
                {mobileErr}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label className="cust-label shiftToLeft">
                Password
                <span className="mandatory-star ml-2">*</span>
              </Form.Label>
              <div className="password-position mb-3">
                <Form.Control
                  className="pass-cust-inputStyle"
                  value={password}
                  style={{ paddingLeft: "1.3rem" }}
                  onChange={(e) => {
                    setIsInfoClick(false);
                    // validatePassword();
                    if (!e.target.value.includes(" ")) {
                      setPassword(e.target.value);
                    }
                    if (e.target.value) {
                      setPasswordErr(false);
                    }
                  }}
                  type={showPass ? "text" : "password"}
                />
                <span
                  class="material-icons"
                  onClick={() => {
                    setShowModal(true);
                  }}
                  style={{ marginRight: "5px", color: "#007bff" }}
                >
                  info
                </span>

                {/* <IconButton
                  style={{fontSize:'50px'}}
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  <Info />
                </IconButton> */}
                {showPass ? (
                  <span
                    class="material-icons"
                    onClick={() => {
                      setShowPass(false);
                    }}
                  >
                    visibility
                  </span>
                ) : (
                  <span
                    class="material-icons"
                    onClick={() => {
                      setShowPass(true);
                    }}
                  >
                    visibility_off
                  </span>
                )}
              </div>
              <Form.Text
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "flex-start",
                  fontFamily: "emoji",
                }}
              >
                {passwordErr}
              </Form.Text>
              <Form.Group className="d-flex justify-content-center mt-2">
                <span style={{fontSize:"12px"}}>By creating an account you agree to Opportunity Bank's  
                  <a href="https://www.zubinfoundation.org/terms-of-use/" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}> Terms of Use</a> and 
                  <a href="https://www.zubinfoundation.org/privacy-policy/" target="_blank" rel="noopener noreferrer" style={{ color: "blue"}}> Privacy Policy</a>.
                </span>
            </Form.Group> 
              <Button className="auth-btn mt-1" onClick={signUpUser}>
                Sign up
              </Button>
            </Form.Group>
            <Form.Group className="d-flex justify-content-center pointer">
              <span className="mb-3">Already have an account?</span> &nbsp;
              <span
                style={{ color: "blue" }}
                onClick={() => {
                  history.push("/signin");
                }}
              >
                Sign in
              </span>
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
}
